body {
  font-family: "prompt", sans-serif;
  font-size: 16px;
  padding: 0;
  margin: 0;
}
header {
  height: 60px;
  position: absolute;
  width: calc(100vw - 17px);
  left: 0;
  top: 0;
  z-index: 101;
}
input:focus {
  outline: none;
}
.header-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header-content {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.wrapper {
  position: relative;
  max-width: 1300px;
  margin: auto;
  padding: 0 15px;
  height: 100%;
}
.hero {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 70vh;
  background: linear-gradient(rgba(0, 0, 0, 0), black),
    url("./images/herobg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.header-logo {
  /*-webkit-text-stroke-width: 0.001px;
  -webkit-text-stroke-color: yellow;*/
}
.hero-main-text {
  font-size: 50px;
  color: white;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.212);
  /*color: yellow;
  text-shadow: 0 0 10px red;*/
  font-weight: bolder;
  max-width: 400px;
  line-height: 1;
  /*-webkit-text-stroke-width: 0.001px;
  -webkit-text-stroke-color: red;*/
}
.hero-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
}
.landing-main-section {
  z-index: 101;
  position: relative;
  margin-top: 70vh;
  /*background-color: rgb(240, 240, 240);*/
  background-color: rgba(0,0,0,0.7);
  padding-bottom: 40px;
}
.hero-form-section {
  position: absolute;
  right: 0;
  bottom: calc(100% - 40px);
  width: 500px;
  border: 1px solid rgba(0, 112, 204, 0.582);
  background: linear-gradient(rgba(0, 86, 156, 0.425), rgb(0, 51, 80));
  border-radius: 10px;
  border-top-right-radius: 100px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.637);
}
.contained-input-fld {
  background-color: #eee;
  border-radius: 50px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.411);
}
.contained-input-fld i {
  margin-left: 14px;
  color: rgb(5, 110, 128);
}
.contained-input-fld input,
.contained-input-fld select {
  width: calc(100% - 70px);
  border: 0;
  background: none;
  padding: 14px;
}
.loan-programs-sections {
  /*background-color: white;*/
  border: 1px solid rgba(0, 0, 0, 0.1);
  /*padding: 10px;*/
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.loan-programs-sections-item {
  width: calc(33% - 30px);
  padding: 5px 15px;
  border-radius: 9px;
  box-shadow: 1px 2px 3px rgba(0,0,0,0.5);
  background-color: rgb(160, 209, 255);
  border-top: 10px solid rgb(148, 183, 235);
}
.loan-programs-sections-item:nth-child(2) {
  border-top: 10px solid rgb(107, 162, 224);
  background-color: rgb(164, 196, 255);
}
.loan-programs-sections-item.last {
  border: none;
  border-top: 10px solid rgb(115, 222, 255);
  background-color: rgb(168, 238, 255);
}
.loan-programs-sections-item-title {
  /*-webkit-text-stroke-width: 0.001px;
  -webkit-text-stroke-color: red;
  color: yellow;
  text-shadow: 0 0 10px rgba(255, 0, 0, 0.329);*/
  font-size: 13px !important;
  color: rgb(0, 60, 109);
}
.body-section-with-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-main-icons-section {
  display: block;
  box-sizing: border-box;
  padding-bottom: 180px;
  /*height: 500px;
  background: linear-gradient(white, #8edcf0);
  background-color: #8edcf0;*/
  background-color: #2b343d;
  /*clip-path: ellipse(84% 100% at 74.48% 0%);*/
  clip-path: ellipse(300% 100% at 230% 0%);
}
.body-section-with-icons-item {
  padding: 15px;
  width: calc(33% - 40px);
}
.body-section-with-icons-item i {
  font-size: 25px;
}
footer {
  position: relative;
  padding: 20px 10px;
  background-color: #002d38;
  /*border-top: 4px solid purple;*/
  border-top: 4px solid red;
}
.loan-programs-section-items-list{
  border-top: 1px solid rgba(0,0,0,0.1);
  padding: 20px 0;
}
.loan-programs-section-items-list p{
  font-size: 14px;
  color: darkblue;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
}
@media only screen and (max-width: 940px) {
  .header-contact-social-icons {
    margin-top: -10px;
  }
  .header-logo img {
    width: 120px !important;
  }
  .hp-page-section-title {
    font-size: 22px !important;
  }
  p {
    text-align: center;
  }
  .header-content {
    display: block;
  }
  .header-content p {
    text-align: center !important;
  }
  .hero-content {
    justify-content: center;
  }
  .hero-form-section {
    width: calc(100% - 20px);
    right: auto;
    left: 10px;
    top: -70px;
    height: 280px;
    border-radius: 0;
    /*border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;*/
    border-radius: 20px;
    padding-top: 0;
    box-shadow: none;
  }
  .hero-main-text {
    text-align: center;
    font-size: 28px;
    max-width: 200px;
    margin: auto;
  }
  .hero-form-section-content-container {
    padding-top: 0 !important;
  }
  .hero-form-section-content-container p:nth-child(1){
    font-size: 20px !important;
  }
  .landing-page-main-icons-section {
    clip-path: ellipse(300% 100% at 150% 0%);
  }
  .body-section-with-icons {
    display: block;
  }
  .body-section-with-icons-item {
    width: calc(100% - 40px);
  }
  .our-promises-section {
    padding-top: 250px !important;
  }
  .loan-programs-sections {
    display: block;
    padding: 0;
  }
  .loan-programs-sections-item {
    width: calc(100% - 30px);
    border-radius: 0;
  }
  .wrapper {
    max-width: 700px;
  }
}
@media only screen and (max-width: 440px) {
}
